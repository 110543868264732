import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';

import { AuthState } from './state';

export const authGuard = () => {
  const store = inject(Store);
  const router = inject(Router);
  const token = store.selectSnapshot(AuthState.accessToken);

  return !!token || router.navigate(['/auth/login']);
};
